import './utils/array';

import { SnackbarProvider } from 'notistack';
import React, { useState } from 'react';
import { NotifierContainer } from 'react-tools';
import { AppRoutingSwitch } from 'routing/router';

import {
    createGenerateClassName, createStyles, makeStyles, StylesProvider, Theme, ThemeProvider
} from '@material-ui/core';
import ScopedCssBaseline from '@material-ui/core/ScopedCssBaseline';
import { Navigator } from '@navigator';

import { AppContextProvider } from './duck';
import { moodThemeDark } from './themes.jss';

const generateClassName = createGenerateClassName({
  productionPrefix: 'oda',
  disableGlobal: true,
});

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    breadcrumbs: {
      padding: theme.spacing(2),
    },
    snackBarContainer: {
      marginBottom: 56,
    },
  })
);

function App() {
  const classes = useStyles();
  return (
    <StylesProvider generateClassName={generateClassName}>
      <ThemeProvider theme={moodThemeDark}>
        <ScopedCssBaseline style={{ height: '100%' }}>
          <SnackbarProvider
            maxSnack={1}
            classes={{ containerAnchorOriginBottomLeft: classes.snackBarContainer }}
          >
            <AppContextProvider>
              <AppRoutingSwitch />
              <NotifierContainer />
            </AppContextProvider>
          </SnackbarProvider>
        </ScopedCssBaseline>
      </ThemeProvider>
    </StylesProvider>
  );
}

export default App;
