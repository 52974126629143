
declare global {
  interface Array<T> {
    alphabeticalSort(property: keyof T, direction: 'asc' | 'desc'): Array<T>;
  }
}

/* eslint-disable no-extend-native */
Array.prototype.alphabeticalSort = function <T>(property: keyof T, direction: 'asc' | 'desc' = 'asc') {
  const sorted = this.sort((a: any, b: any) => {
    var textA = a[property].toUpperCase();
    var textB = b[property].toUpperCase();
    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
  });

  return direction === 'asc' ? sorted : sorted.reverse();
};

export { };