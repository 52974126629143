import React, { useCallback, useMemo, useEffect } from 'react';
import { SingleSelectList, useSearchDialog } from '../@shared';

import { Typography } from '@material-ui/core';
import { useChannels, useLoading, useGetSetChannel } from '../duck';

import Search from '@material-ui/icons/Search';
import Close from '@material-ui/icons/Close';

import { Channel } from '@models';
import { IdName, ButtonItem } from 'react-tools';
import { useBarButtons, useTitle, usePushRoute, AppRoute } from 'routing';
import { useStyles } from './styles.jss';
import { SkeletonPlaceholder } from '@shared/skeleton-list';

export const ChannelView = () => {
  const classes = useStyles();

  const loading = useLoading();
  const pushRoute = usePushRoute();

  const allChannels = useChannels();
  const [channel, setChannel] = useGetSetChannel();

  useEffect(() => {
    if (allChannels.length === 1 && !channel) {
      onSelect(allChannels[0]);
    }
  }, [allChannels, channel]);

  const { query, toggleSearchDialog, Dialog, searchOpen } = useSearchDialog('Search Channels');

  const onSelect = useCallback((channel: IdName) => {
    setChannel(channel as Channel);
    pushRoute(AppRoute.EventView);
  }, []);

  useTitle('Select a channel');

  const buttons = useMemo<ButtonItem[]>(() => [{
    icon: () => searchOpen ? <Close /> : <Search />,
    onClick: toggleSearchDialog
  }], [searchOpen]);

  useBarButtons(buttons);

  const channels = useMemo(
    () => allChannels.filter(e => e.name.toLowerCase().indexOf(query.toLowerCase()) > -1),
    [query, allChannels]
  );

  const noItemsFoundMessage = useMemo(
    () => allChannels.length === 0 ? 'No channels available' : (channels.length === 0 ? 'No channels found' : ''),
    [allChannels.length, channels.length]
  );

  const noItemsFound = useMemo(() => channels.length === 0, [channels]);

  return loading ? <SkeletonPlaceholder /> : (
    <div className={classes.root}>
      {Dialog}
      {
        noItemsFound ? (
          <div className={classes.noItems}>
            <Typography align={'center'} color={'textPrimary'}>{noItemsFoundMessage}</Typography>
          </div>) : (
            <div className={classes.listContainer}>
              <SingleSelectList items={channels} onSelect={onSelect} />
            </div>
          )
      }

    </div>
  )
}

