import { makeStyles, Theme, createStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      height: '100%'
    },
    noItems: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      flexGrow: 1,
      marginTop: '-25%',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    },
    listContainer: {
      flexGrow: 1,
      height: 0,
      overflow: 'auto'
    }
  }),
);