import React, { useCallback } from "react";

import { Breadcrumbs, Link, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";

import { NavigatorItem } from "./models";

export const useStyles = makeStyles(() =>
  createStyles({
    link: {
      cursor: "pointer",
    },
    linkContent: {
      maxWidth: "30vw",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
    },
  })
);

export interface NavigatorBreadcrumbsProps<T> {
  path: NavigatorItem<T>[];
  rootLabel: string;
  selectRootItem: () => void;
  selectItem: (item: NavigatorItem<T>) => void;
  classes?: {
    root?: string;
  };
}

export const NavigatorBreadcrumbs = <T extends unknown>(
  props: NavigatorBreadcrumbsProps<T>
) => {
  const classes = useStyles();

  const navigate = useCallback(
    (item: NavigatorItem<T>) => (event: React.MouseEvent) => {
      const isLast = props.path[props.path.length - 1].id === item.id;

      if (!isLast) {
        props.selectItem(item);
      }
    },
    [props.selectItem, props.path]
  );

  const navigateToRoot = useCallback(
    () => props.selectRootItem(),
    [props.selectRootItem]
  );

  return (
    <>
      {props.path.length > 0 && (
        <Breadcrumbs maxItems={2} classes={{ root: props.classes?.root }}>
          <Typography
            className={classes.link}
            onClick={navigateToRoot}
            data-testid='navigator-root-button'
          >
            {props.rootLabel}
          </Typography>
          {props.path.map((item) => (
            <Link
              key={item.id}
              className={classes.link}
              id={item.id.toString()}
              onClick={navigate(item)}
              color='inherit'
            >
              <Typography className={classes.linkContent}>
                {item.label}
              </Typography>
            </Link>
          ))}
        </Breadcrumbs>
      )}
    </>
  );
};
