import React from 'react';

import { useMemo } from 'react';

import Close from '@material-ui/icons/Close';
import More from '@material-ui/icons/MoreHoriz';
import Search from '@material-ui/icons/Search';
import { useBarButtons } from 'routing';
import { usePlayers } from 'duck';

export const usePlayerViewBarButtons = (
  searchOpen: boolean, toggleSearchDialog: () => void,
  setMenuOpen: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const barButtons = useMemo(() => [{
    icon: () => searchOpen ? <Close /> : <Search />,
    onClick: toggleSearchDialog
  }, {
    icon: () => <More />,
    onClick: () => setMenuOpen(prev => !prev)
  }], [searchOpen]);

  useBarButtons(barButtons);
};

export const useFilteredPlayers = (query: string) => {
  const allPlayers = usePlayers();

  const players = useMemo(
    () =>
      allPlayers
        ? allPlayers
          .filter(
            (e) =>
              `${e.site.name.toLowerCase()} - ${e.name.toLowerCase()}`.indexOf(
                query.toLowerCase()
              ) > -1
          )
          .alphabeticalSort("name", "asc")
        : [],
    [allPlayers, query]
  );

  return {
    players,
    allPlayers,
  };
};