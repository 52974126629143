import React, { useCallback, useState, useMemo, useEffect } from 'react';
import {
  useChannel,
  useGetSetEvent,
  useEvents,
  useGetSetDeviceIds,
} from 'duck';
import { useTitle, useBarButtons, usePushRoute, AppRoute } from 'routing';
import { SingleSelectList, BottomAppBar, useSearchDialog } from '@shared';

import Search from '@material-ui/icons/Search';
import Right from '@material-ui/icons/ArrowForward';
import Close from '@material-ui/icons/Close';

import { IdName, ButtonItem } from 'react-tools';
import { useStyles } from './styles.jss';
import { Typography } from '@material-ui/core';

export const EventView = () => {
  const channel = useChannel();
  useTitle(channel ? channel.name : 'Events');

  const pushRoute = usePushRoute();
  const classes = useStyles();

  const { event, setEvent } = useGetSetEvent();
  const allEvents = useEvents();
  const { setSelectedDeviceIds } = useGetSetDeviceIds();

  useEffect(() => {
    setSelectedDeviceIds([]);
  }, []);

  const [selected, setSelected] = useState<IdName | null>();

  const onSelect = useCallback((event: IdName) => setSelected(event), []);

  const { query, toggleSearchDialog, Dialog, searchOpen } =
    useSearchDialog('Search Events');

  useEffect(() => {
    if (allEvents.length === 1 && !event) {
      setEvent(allEvents[0]);
      pushRoute(AppRoute.PlayerView);
    }
  }, [allEvents]);

  const handleNext = useCallback(() => {
    if (selected) {
      setEvent(selected);
      pushRoute(AppRoute.PlayerView);
    }
  }, [selected]);

  const bottomButtons = useMemo<ButtonItem[]>(
    () => [
      {
        onClick: handleNext,
        icon: () => <Right />,
        tooltip: 'Next',
      },
    ],
    [selected]
  );

  const buttons = useMemo<ButtonItem[]>(
    () => [
      {
        icon: () => (searchOpen ? <Close /> : <Search />),
        onClick: toggleSearchDialog,
      },
    ],
    [searchOpen]
  );

  useBarButtons(buttons);

  const events = useMemo(
    () =>
      allEvents
        .filter((e) => e.name.toLowerCase().indexOf(query.toLowerCase()) > -1)
        .alphabeticalSort('name', 'asc'),
    [allEvents, query]
  );

  const noItemsFoundMessage = useMemo(
    () =>
      allEvents.length === 0
        ? `No events available for ${channel?.name}`
        : events.length === 0
        ? 'No events found'
        : '',
    [allEvents.length, events.length, channel]
  );

  const noItemsFound = useMemo(() => events.length === 0, [events]);

  return (
    <div className={classes.root}>
      {Dialog}
      {noItemsFound ? (
        <div className={classes.noItems}>
          <Typography align={'center'} color={'textPrimary'}>
            {noItemsFoundMessage}
          </Typography>
        </div>
      ) : (
        <div className={classes.listContainer}>
          <SingleSelectList
            items={events}
            onSelect={onSelect}
            showRadio={true}
            defaultSelected={selected}
          />
        </div>
      )}
      <BottomAppBar buttons={bottomButtons} />
    </div>
  );
};
