import React, { useMemo } from 'react';
import { Skeleton } from '@material-ui/lab';

import { makeStyles, Theme, createStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    item: {
      margin: theme.spacing(2),
      display: 'flex'
    },
    checkbox: {
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(1)
    }
  }),
);

const SkeletonListItem = () => {
  const width = useMemo(() => Math.random() * (90 - 50) + 50, []);
  const classes = useStyles(width);

  return (
    <div className={classes.item}>
      <Skeleton animation="wave" height={34} width={20} className={classes.checkbox} />
      <Skeleton animation="wave" height={34} width={`${width}%`} />
    </div>
  );
}

export const SkeletonPlaceholder = () => (
  <>
    <SkeletonListItem />
    <SkeletonListItem />
    <SkeletonListItem />
    <SkeletonListItem />
    <SkeletonListItem />
    <SkeletonListItem />
  </>
);