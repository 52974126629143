import { useAddressingLoading, useEvent, useGetSetDeviceIds, useLiveCommands } from 'duck';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ButtonItem, IdName } from 'react-tools';
import { AppRoute, usePushRoute, useTitle } from 'routing';

import { Divider, Switch, Typography } from '@material-ui/core';
import Play from '@material-ui/icons/PlayCircleFilled';
import Stop from '@material-ui/icons/Stop';
import { Device, IPlayer } from '@models';
import { BottomAppBar, MultipleSelectList, useSearchDialog } from '@shared';
import { SkeletonPlaceholder } from '@shared/skeleton-list';

import { ConfirmDialog } from './confirm-dialog';
import { DrawerMenu, PlayerMenuAction } from './drawer-menu';
import { useFilteredPlayers, usePlayerViewBarButtons } from './hooks';
import { useStyles } from './styles.jss';
import { TimerDialog } from './timer-dialog';

const startEventTitle = 'Start On Demand Event?';
const startEventText = (eventName: string, count: number) =>
  `Regular playback will end and "${eventName}" event will begin on ${count} selected device${count === 1 ? '' : 's'}.`;

const endEventTitle = 'End On Demand Event?';
const endEventText = (count: number) => 
  `The On Demand event will end and regular playback will resume on ${count} selected device${count === 1 ? '' : 's'}.`;

export const PlayerView = () => {
  const classes = useStyles();

  const event = useEvent();

  useTitle(event ? event.name : 'Select Devices');
  const addressingLoading = useAddressingLoading();

  const { selectedDeviceIds, setSelectedDeviceIds } = useGetSetDeviceIds();
  const [selected, setSelected] = useState<Device[]>([]);

  const [menuOpen, setMenuOpen] = useState(false);
  const [timerOpen, setTimerOpen] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [ignoreIfPlaying, setIgnoreIfPlaying] = useState(false);
  const [eventType, setEventType] = useState<'play' | 'stop' | null>();

  const { Dialog, query, searchOpen, toggleSearchDialog } =
    useSearchDialog('Search devices');

  const { players, allPlayers } = useFilteredPlayers(query);

  const { playEvents, stopEvents } = useLiveCommands();
  const pushRoute = usePushRoute();

  usePlayerViewBarButtons(searchOpen, toggleSearchDialog, setMenuOpen);

  useEffect(() => {
    setSelected(
      selectedDeviceIds.map((e) => allPlayers.find((f) => f.id === e) as Device)
    );
  }, [allPlayers.length, selectedDeviceIds]);

  const onEventClicked = useCallback(
    (eventType: 'play' | 'stop') => () => {
      if (selected.length) {
        setEventType(eventType);
        setConfirmOpen(true);
      }
    },
    [selected]
  );

  const bottomButtons = useMemo<ButtonItem[]>(
    () => [
      {
        onClick: onEventClicked('stop'),
        icon: () => <Stop />,
        tooltip: 'Stop',
      },
      {
        onClick: onEventClicked('play'),
        icon: () => <Play />,
        tooltip: 'Play Now',
      },
    ],
    [onEventClicked]
  );

  const onMenuAction = useCallback(
    (menuOption: PlayerMenuAction) => {
      setMenuOpen(false);
      switch (menuOption) {
        case PlayerMenuAction.SelectByGroup:
          pushRoute(AppRoute.GroupView);
        case PlayerMenuAction.SelectAll:
          return setSelected(allPlayers);
        case PlayerMenuAction.UnselectAll:
          return setSelected([]);
        default:
          setSelected(
            allPlayers.filter(
              (e) => selected.findIndex((f) => f.id === e.id) === -1
            )
          );
      }
    },
    [allPlayers, selected]
  );

  const onSelect = useCallback(
    (players: IdName[]) => setSelectedDeviceIds(players.map((e) => e.id)),
    []
  );
  const toggleMenu = useCallback(() => setMenuOpen((prev) => !prev), []);

  const label = useCallback((p: IdName) => {
    return `${(p as Device).site.name} - ${(p as Device).name}`;
  }, []);

  const handleIgnoreIfPlayingSwitch = useCallback(
    (event: any, checked: boolean) => {
      setIgnoreIfPlaying(checked);
    },
    []
  );

  const handleConfirm = useCallback(() => {
    const serials = selected.map((e) => e.serialNumber);
    if (serials.length) {
      if (eventType === 'play') {
        playEvents(serials, ignoreIfPlaying);
      } else {
        stopEvents(serials);
      }
    }
    setTimeout(() => setTimerOpen(false), 0);
  }, [selected, ignoreIfPlaying, eventType]);

  const { confirmText, confirmTitle, confirmLabel } = useMemo(
    () => ({
      confirmText:
        eventType === 'play'
          ? startEventText(event ? event.name : '', selectedDeviceIds.length)
          : endEventText(selectedDeviceIds.length),
      confirmTitle: eventType === 'play' ? startEventTitle : endEventTitle,
      confirmLabel: eventType === 'play' ? 'START' : 'END',
    }),
    [eventType, selectedDeviceIds]
  );

  const onConfirm = useCallback(() => {
    setTimerOpen(true);
    setConfirmOpen(false);
  }, []);

  const onConfirmCancel = useCallback(() => setConfirmOpen(false), []);

  const noItemsFoundMessage = useMemo(
    () =>
      allPlayers.length === 0
        ? `No devices available for ${event?.name}`
        : players.length
        ? 'No devices found'
        : '',
    [allPlayers.length, allPlayers.length, event]
  );

  const noItemsFound = useMemo(() => players.length === 0, [players]);

  return addressingLoading ? (
    <SkeletonPlaceholder />
  ) : (
    <div className={classes.root}>
      {Dialog}

      <div className={classes.switchContainer}>
        <Typography color={'textPrimary'}>Reload if playing</Typography>
        <Switch
          name='checkedA'
          color={'secondary'}
          onChange={handleIgnoreIfPlayingSwitch}
        />
      </div>

      <Divider />

      {noItemsFound ? (
        <div className={classes.noItems}>
          <Typography align={'center'} color={'textPrimary'}>
            {noItemsFoundMessage}
          </Typography>
        </div>
      ) : (
        <div className={classes.listContainer}>
          <MultipleSelectList
            items={players as IdName[]}
            label={label}
            onSelect={onSelect}
            defaultSelection={selected}
          />
          <TimerDialog
            open={timerOpen}
            confirm={handleConfirm}
            onClose={() => setTimerOpen(false)}
          />

          <ConfirmDialog
            open={confirmOpen}
            confirm={onConfirm}
            cancel={onConfirmCancel}
            cancelLabel={'Cancel'}
            confirmLabel={confirmLabel}
            text={confirmText}
            title={confirmTitle}
          />
        </div>
      )}

      <BottomAppBar buttons={bottomButtons} />

      <DrawerMenu open={menuOpen} toggle={toggleMenu} onClick={onMenuAction} />
    </div>
  );
};
