import { unmountComponentAtNode } from "react-dom";

import { startReact } from "./start-react";
import { createDefaultStore } from "./store";

export const ODA: any = {
  open: (rootNodeId: string = "root") => {
    startReact(rootNodeId, createDefaultStore());
  },
  unmount: (rootNodeId: string) => {
    unmountComponentAtNode(document.getElementById(rootNodeId) as Element);
  },
  homeFn: () => {},
  userId: 7149,
  workgroupId: 19706,
};

export const setupODA = () => {
  const setGlobalODA = () => {
    // set AP as a global property
    window.ODA = ODA;
  };

  const onDOMContentLoaded = (event: Event) => {
    // remove the event listener
    document.removeEventListener("DOMContentLoaded", onDOMContentLoaded);

    const mvisionLoaded: boolean =
      typeof window.mvision === "object" &&
      typeof window.mvision.onDemandApp === "object" &&
      window.mvision.onDemandApp.ODA_REQUIRED;

    if (!mvisionLoaded) {
      // fallback to the "natural" react code
      startReact("root");
    }

    setGlobalODA();
  };

  /**
   * If the document and the mvision.services namespace are already loaded
   * we set the global AP object...
   */
  if (document.readyState === "complete") {
    const mvisionLoaded: boolean =
      typeof window.mvision === "object" &&
      typeof window.mvision.onDemandApp === "object" &&
      window.mvision.onDemandApp.ODA_REQUIRED;

    if (mvisionLoaded) {
      setGlobalODA();
    }
  } else {
    // ...otherwise we listen for the document to be ready
    document.addEventListener("DOMContentLoaded", onDOMContentLoaded);
  }
};
