import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle,
  Typography
} from '@material-ui/core';
import Done from '@material-ui/icons/Done';

import { useStyles } from './styles.jss';


interface Props {
  open: boolean;
  onClose: () => void;
  confirm: () => void;
}

export const TimerDialog = ({ open, onClose, confirm }: Props) => {
  const MemoTimer = useMemo(() => <ApplySettingsCancelTimer onComplete={confirm} />, [confirm]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth='sm' fullWidth={true}
    >
      <DialogTitle>Cancel Changes</DialogTitle>

      <DialogContent>
        {MemoTimer}
      </DialogContent>

      <DialogActions >
        <Button onClick={onClose} variant={'contained'} color={'secondary'}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog >
  )
}


const MS = 5000;
const STEPS = 50;

const ApplySettingsCancelTimer = ({ onComplete }: { onComplete: () => void }) => {
  const classes = useStyles();

  const [elapsed, setElapsed] = React.useState(0);
  const startTimeRef = React.useRef<number>(0);

  useEffect(() => {
    const progress = () => {
      setElapsed(previous => {
        if (previous >= MS) {
          clearInterval(timer);
          onComplete();
          return MS;
        }

        return (+new Date() - startTimeRef.current);
      });
    }

    startTimeRef.current = +new Date();
    const timer = setInterval(progress, MS / STEPS);
    return () => clearInterval(timer);
  }, []);

  const secondsRemaining = useMemo(() => Math.ceil((MS - elapsed) / 1000), [elapsed]);
  const completed = useMemo(() => 100 * (elapsed / MS), [elapsed]);

  return (
    <>
      <Typography variant='h6' align='center'>
        {completed < 100 ? 'You can still cancel' : 'Done'}
      </Typography>
      <div className={classes.countInputContainer}>
        {completed < 100 &&
          <Typography variant='h3' className={classes.progressText}>
            {secondsRemaining}
          </Typography>
        }

        {completed >= 100 &&
          <Done className={classes.completedIcon} />
        }


        <CircularProgress className={classes.progress} variant="static" value={completed} color='secondary' size={120} />
      </div>
    </>
  )
}