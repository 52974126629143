import { List, Paper } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { NavigatorItem } from "./models";
import { NavigatorBreadcrumbs } from "./Breadcrumbs";
import { useNavigatorItemSelection } from "./hooks";
import { NavigatorListItem, NavigatorListItemProps } from "./NavigatorListItem";

export interface NavigatorProps<T> {
  items: NavigatorItem<T>[];
  itemSelected: (item: NavigatorItem<T>) => void;
  itemComponent?: (props: NavigatorListItemProps<T>) => JSX.Element;
  filter?: string;
  breadcrumbRootLabel: string;
  extraButtonClick?: (buttonId: string, item: NavigatorItem<T>) => void;
  disableInternalNavigation?: boolean;
  classes?: {
    root?: string;
    paperRoot?: string;
    breadcrumb?: string;
  };
}

export const Navigator = <T extends unknown>(props: NavigatorProps<T>) => {
  const [initialItems] = useState(props.items);

  const ListItemComponent = props.itemComponent;

  const [items, setItems] = useState(props.items);

  const [filteredItems, setFilteredItems] = useState(props.items);

  useEffect(() => {
    if (props.disableInternalNavigation) {
      setItems(props.items);
    }
  }, [props.items, props.disableInternalNavigation]);

  const { selectItem, selectRootItem, path } = useNavigatorItemSelection(
    props.itemSelected,
    initialItems,
    setItems
  );

  useEffect(() => {
    if (typeof props.filter === "string") {
      const filter: string = props.filter;
      setFilteredItems(
        items.filter((e) =>
          e.label.toLowerCase().includes(filter.toLowerCase())
        )
      );
    }
  }, [props.filter, items]);

  return (
    <div className={props.classes?.root}>
      <NavigatorBreadcrumbs
        path={path}
        selectRootItem={selectRootItem}
        rootLabel={props.breadcrumbRootLabel}
        selectItem={selectItem}
        classes={{ root: props.classes?.breadcrumb }}
      />
      <Paper classes={{ root: props.classes?.paperRoot }}>
        {!ListItemComponent && (
          <List component='nav' aria-labelledby='group-navigator'>
            {filteredItems.map((item) => (
              <NavigatorListItem
                key={item.id}
                item={item}
                selectItem={selectItem}
              />
            ))}
          </List>
        )}
        {!!ListItemComponent &&
          filteredItems.map((item) => (
            <ListItemComponent
              item={item}
              selectItem={selectItem}
              key={item.id}
              extraButtonClick={props.extraButtonClick}
            />
          ))}
      </Paper>
    </div>
  );
};
