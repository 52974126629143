import {
  Button,
  Collapse,
  createStyles,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Theme,
  useMediaQuery,
  useTheme,
  Divider,
} from "@material-ui/core";
import React, { useCallback, useMemo, useState } from "react";
import { NavigatorListItemProps } from "../@navigator/NavigatorListItem";

import RightArrow from "@material-ui/icons/KeyboardArrowRight";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Folder from "@material-ui/icons/Folder";

import { NavigatorItem } from "../@navigator/models";
import { GroupTreeItem } from "@models";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actions: {
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
    },
    smActions: {
      display: "flex",
      width: "100%",
      flexDirection: "column",

      "& button:first-child": {
        marginBottom: theme.spacing(1),
      },
    },
    listItemIcon: {
      minWidth: 0,
    },
    folderListItemIcon: {
      minWidth: 0,
      marginRight: theme.spacing(1),
    },
    listItem: {
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
    },
  })
);

export enum GroupViewNavigatorListItemAction {
  Unselect = "UNSELECT",
  Select = "SELECT",
}

interface ButtonsProps<T> {
  extraButtonClick?: (buttonId: string, item: NavigatorItem<T>) => void;
  item: NavigatorItem<T>;
}

const Buttons = <T extends unknown>({
  extraButtonClick,
  item,
}: ButtonsProps<T>) => {
  const onExtraButtonClicked = useCallback(
    (buttonId: string) => () => {
      if (extraButtonClick) {
        extraButtonClick(buttonId, item);
      }
    },
    [extraButtonClick, item]
  );

  return (
    <>
      <Button
        onClick={onExtraButtonClicked(
          GroupViewNavigatorListItemAction.Unselect
        )}
        data-testid='group-view-item-unselect-button'
        variant={"outlined"}
      >
        Unselect
      </Button>
      <Button
        onClick={onExtraButtonClicked(GroupViewNavigatorListItemAction.Select)}
        variant={"contained"}
        color={"secondary"}
        data-testid='group-view-item-select-button'
      >
        Select
      </Button>
    </>
  );
};

export const GroupViewNavigatorListItem = ({
  item,
  selectItem,
  extraButtonClick,
}: NavigatorListItemProps<GroupTreeItem>) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const theme = useTheme();
  const isSm = useMediaQuery(`(max-width: ${theme.breakpoints.values.sm}px)`);

  const hasChildren = useMemo<boolean>(
    () => item.hasChildren || (!!item.children && item.children.length > 0),
    [item]
  );

  const toggleCollapse = useCallback(
    () => (hasChildren ? selectItem(item) : setOpen(!open)),
    [open, hasChildren, item, selectItem]
  );

  return (
    <>
      <ListItem
        button
        data-testid='group-view-navigator-list-item'
        onClick={toggleCollapse}
      >
        {hasChildren && (
          <ListItemIcon classes={{ root: classes.folderListItemIcon }}>
            <Folder />
          </ListItemIcon>
        )}
        <ListItemText
          data-testid='group-view-navigator-list-item-button'
          primary={item.label}
          classes={{ primary: classes.listItem }}
          onClick={() => selectItem(item)}
        />
        {hasChildren && (
          <ListItemIcon classes={{ root: classes.listItemIcon }}>
            <RightArrow />
          </ListItemIcon>
        )}

        {!hasChildren && (
          <ListItemIcon
            data-testid='group-view-navigator-list-item-expand'
            classes={{ root: classes.listItemIcon }}
          >
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItemIcon>
        )}
      </ListItem>
      <Collapse in={open} timeout='auto' unmountOnExit>
        <List component='div' disablePadding>
          <ListItem>
            <div className={isSm ? classes.smActions : classes.actions}>
              <Buttons item={item} extraButtonClick={extraButtonClick} />
            </div>
          </ListItem>
        </List>
      </Collapse>
      <Divider />
    </>
  );
};
