import React from 'react';
import { ButtonItem } from 'react-tools';

import { AppBar, createStyles, makeStyles, Theme, Toolbar, Typography } from '@material-ui/core';

import { BarButton } from './bottom-bar';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      top: 'auto',
      bottom: 0,
    },
    toolbar: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    barButtonRoot: {
      display: 'flex',
    },
    rightBarButton: {
      marginRight: theme.spacing(1),
    },
  })
);

interface BottomAppBarProps {
  buttons: ButtonItem[];
  count: number;
}

export const CountBottomAppBar = ({ buttons, count }: BottomAppBarProps) => {
  const classes = useStyles();

  return (
    <AppBar position={'relative'} className={classes.root} color={'secondary'}>
      <Toolbar className={classes.toolbar}>
        <Typography variant={'body1'} data-testid='count-bottom-bar-text'>
          {count} SELECTED
        </Typography>
        <div>
          {buttons.length === 1 ? (
            <>
              <div></div>
              {buttons.map((button, index) => (
                <BarButton button={button} key={index} index={index + 1} />
              ))}
            </>
          ) : (
            buttons.map((button, index) => (
              <BarButton button={button} key={index} index={index} />
            ))
          )}
        </div>
      </Toolbar>
    </AppBar>
  );
};
