import { createStyles, Theme, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
    },
    root: {
      backgroundColor: 'transparent !important',
    },
    title: {
      flexGrow: 1,
      textAlign: 'center',
    },
    toolbar: {
      backgroundColor: theme.palette.primary.main,
    },
    searchFieldInputControl: {
      height: `calc(${theme.mixins.toolbar.minHeight}px - ${
        theme.spacing(1) * 2
      }px)`,
    },
    searchFieldInput: {
      borderRadius: '18px',
      height: '100%',

      '& input': {
        padding: 0,
      },
    },
    searchFieldIcon: {
      marginTop: `${theme.spacing(0.6)}px !important`,
    },
  })
);
