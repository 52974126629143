import { makeStyles, Theme, createStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      position: 'relative'
    },
    listContainer: {
      flexGrow: 1,
      height: 0,
      overflow: 'auto'
    },
    switchContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      margin: theme.spacing(2),
      alignItems: 'center',
      position: 'sticky',
      top: 0
    },
    noItems: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      flexGrow: 1,
      marginTop: '-25%',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    },

    progress: {
      marginLeft: 'auto',
      marginRight: 'auto'
    },

    progressText: {
      position: 'absolute',
      width: '100%',
      textAlign: 'center',
      top: `calc(50% - ${theme.spacing(3.5)}px)`
    },

    completedIcon: {
      color: theme.palette.success.main,
      position: 'absolute',
      width: '100%',
      textAlign: 'center',
      fontSize: theme.typography.h2.fontSize,
      top: `calc(50% - ${theme.spacing(4)}px)`
    },
    countInputContainer: {
      display: 'flex',
      marginTop: theme.spacing(1),
      position: 'relative'
    },
  }),
);

