import { useCallback, useState } from 'react';
import { NavigatorItem } from './models';

export const useNavigatorItemSelection = <T>(
  itemSelected: (item: NavigatorItem<T>) => void,
  initialItems: NavigatorItem<T>[],
  setItems: (items: NavigatorItem<T>[]) => void
) => {
  const [path, setPath] = useState<NavigatorItem<T>[]>([]);

  const selectItem = useCallback(
    (item: NavigatorItem<T>) => {
      const indexInPath = path.findIndex((e) => e.id === item.id);

      const hasExplicitChildren = item.children && item.children.length > 0;
      const hasChildren = item.hasChildren || hasExplicitChildren;
      const breadcrumbItemClicked = indexInPath > -1;

      if (breadcrumbItemClicked) {
        setPath(path.slice(0, indexInPath + 1));
      } else {
        if (hasChildren) {
          setPath([...path, item]);
        }
      }

      if (hasChildren && hasExplicitChildren) {
        setItems(item.children as NavigatorItem<T>[]);
      }

      itemSelected(item);
    },
    [path, itemSelected]
  );

  const selectRootItem = useCallback(() => {
    setItems(initialItems);
    setPath([]);
  }, [initialItems]);

  return {
    selectItem,
    selectRootItem,
    path,
  };
};
