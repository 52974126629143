import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    navigatorRoot: {
      padding: theme.spacing(2),
      flexGrow: 1,
      overflow: 'auto'
    },
    title: {
      marginTop: theme.spacing(2),
    },
    contentPadding: {
      padding: theme.spacing(2),
    },
    breadcrumbs: {
      marginBottom: theme.spacing(1),
    },
  })
);
