import React, {
  useCallback,
  useState,
  useMemo,
  useEffect,
  useRef,
} from 'react';
import {
  AppBar,
  Toolbar,
  InputAdornment,
  TextField,
  Slide,
  Collapse,
} from '@material-ui/core';
import Search from '@material-ui/icons/Search';
import { useDebouncedCallback } from 'use-debounce';

import { useStyles } from './search-dialog.jss';

interface SearchDialogProps {
  open: boolean;
  title: string;
  query: string;
  delayMs?: number;
  handleClose: () => void;
  onSearch: (query: string) => void;
}

export const SearchDialog = ({
  open,
  title,
  delayMs,
  onSearch,
  query,
}: SearchDialogProps) => {
  const classes = useStyles();
  const inputRef = useRef<HTMLInputElement | null>();

  const [debouncedHandleOnChange] = useDebouncedCallback(
    (query: string) => onSearch(query),
    delayMs ? delayMs : 300
  );

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      event.persist();
      setValue(event.target.value);
      debouncedHandleOnChange(event.target.value);
    },
    []
  );

  const [value, setValue] = useState(query);

  useEffect(() => {
    setValue(query);
  }, [query]);

  useEffect(() => {
    if (inputRef && inputRef.current) {
      inputRef.current?.focus();
    }
  }, [open]);

  return (
    <Collapse in={open} style={{ position: 'sticky', zIndex: 1200, top: '0' }}>
      <AppBar position={'sticky'}>
        <Toolbar className={classes.toolbar}>
          <TextField
            inputRef={inputRef}
            autoFocus
            onChange={handleChange}
            placeholder={title}
            value={value}
            fullWidth
            classes={{
              root: classes.searchFieldInputControl,
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment
                  position='start'
                  className={classes.searchFieldIcon}
                >
                  <Search />
                </InputAdornment>
              ),
              className: classes.searchFieldInput,
              disableUnderline: true,
            }}
            variant={'filled'}
            size='small'
          />
        </Toolbar>
      </AppBar>
    </Collapse>
  );
};

export const useSearchDialog = (title: string) => {
  const [searchOpen, setSearchOpen] = useState(false);
  const [query, setQuery] = useState('');

  const onSearch = useCallback((newQuery: string) => setQuery(newQuery), []);

  const toggleSearchDialog = useCallback(
    () =>
      setSearchOpen((open) => {
        const newOpenState = !open;
        if (!newOpenState) {
          setQuery('');
        }

        return newOpenState;
      }),
    []
  );

  const Dialog = useMemo(
    () => (
      <SearchDialog
        query={query}
        handleClose={toggleSearchDialog}
        open={searchOpen}
        title={title}
        onSearch={onSearch}
      />
    ),
    [query, title, searchOpen]
  );

  return {
    toggleSearchDialog,
    query,
    searchOpen,
    Dialog,
  };
};
