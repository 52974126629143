import { useAppContext, useGetSetDeviceIds } from "duck";
import React, { useCallback, useEffect, useMemo } from "react";
import { ButtonItem } from "react-tools";
import { AppRoute, useBarButtons, usePushRoute } from "routing";

import { Typography } from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import Search from "@material-ui/icons/Search";
import { GroupTreeItem } from "@models";
import { Navigator, NavigatorItem } from "@navigator";
import { CountBottomAppBar, useSearchDialog } from "@shared";

import {
  GroupViewNavigatorListItem,
  GroupViewNavigatorListItemAction,
} from "./group-view-navigator-list-item";
import { useStyles } from "./group-view.jss";
import { useGroupFolders } from "./hooks";

export const GroupView = () => {
  const { toggleSearchDialog, Dialog, searchOpen, query } =
    useSearchDialog("Search");

  const classes = useStyles();
  const appContext = useAppContext();

  const buttons = useMemo<ButtonItem[]>(
    () => [
      {
        icon: () => (searchOpen ? <Close /> : <Search />),
        onClick: toggleSearchDialog,
      },
    ],
    [searchOpen, toggleSearchDialog]
  );

  useBarButtons(buttons);
  const { setSelectedDeviceIds, selectedDeviceIds } = useGetSetDeviceIds();

  useEffect(() => {}, [selectedDeviceIds]);

  const pushRoute = usePushRoute();
  const folders = useGroupFolders();

  const itemSelected = useCallback((item: NavigatorItem<GroupTreeItem>) => {},
  []);

  const extraButtonClick = useCallback(
    (buttonId: string, item: NavigatorItem<GroupTreeItem>) => {
      const newDeviceList = selectedDeviceIds.filter(
        (f) => !item.value.devices.find((e) => e.id === f)
      );

      if (buttonId === GroupViewNavigatorListItemAction.Select) {
        const newItems = item.value.devices.filter(
          (d) => selectedDeviceIds.indexOf(d.id) === -1
        );
        appContext.showToast(
          newItems.length === 0
            ? "Devices are already selected"
            : `${newItems.length} device${
                newItems.length === 1 ? "" : "s"
              } added to selection.`,
          "default"
        );
        newDeviceList.push(...item.value.devices.map((e) => e.id));
      } else {
        const removedItems = selectedDeviceIds.length - newDeviceList.length;

        appContext.showToast(
          removedItems === 0
            ? "Devices are not part of the selection"
            : `${removedItems} device${
                removedItems === 1 ? "" : "s"
              } removed from selection.`,
          "default"
        );
      }

      setSelectedDeviceIds(newDeviceList);
    },
    [selectedDeviceIds, setSelectedDeviceIds]
  );

  const close = useCallback(() => pushRoute(AppRoute.PlayerView), [pushRoute]);

  const MemoizedNavigator = useMemo(
    () => (
      <Navigator
        classes={{
          root: classes.navigatorRoot,
          breadcrumb: classes.breadcrumbs,
        }}
        itemComponent={GroupViewNavigatorListItem}
        breadcrumbRootLabel='Root'
        itemSelected={itemSelected}
        extraButtonClick={extraButtonClick}
        filter={query}
        items={folders as NavigatorItem<GroupTreeItem>[]}
      />
    ),
    [folders, itemSelected, extraButtonClick, query]
  );

  return (
    <div className={classes.root}>
      {Dialog}

      <Typography variant={"h4"} align={"center"} className={classes.title}>
        Select By Group
      </Typography>

      <Typography
        variant={"subtitle2"}
        color={"textSecondary"}
        className={classes.contentPadding}
      >
        To select all devices within the group, expand the group and press
        "select". To unselect all devices within the group, press "unselect".
      </Typography>

      {MemoizedNavigator}

      <CountBottomAppBar
        buttons={[
          {
            icon: () => <Close />,
            tooltip: "CLOSE",
            onClick: () => close(),
          },
        ]}
        count={selectedDeviceIds.length}
      />
    </div>
  );
};
