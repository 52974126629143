import { useContext } from 'react';
import { ButtonItem } from 'react-tools';

import { AppRoute, AppRoutingContext } from './routing.context';

export const useRoutingContext = () => {
  return useContext(AppRoutingContext);
};

export const usePushRoute = () => {
  const context = useRoutingContext();

  return (route: AppRoute) => {
    context.pushRoute(route);
  };
};

export const useCurrentRoute = () => {
  const context = useRoutingContext();
  return context.currentRoute;
};

export const useTitle = (title: string) => {
  const context = useRoutingContext();
  context.setTitle(title);
};

export const useBarButtons = (buttons: ButtonItem[]) => {
  const context = useRoutingContext();
  context.setBarButtons(buttons);
};
