import React from 'react';
import { ButtonItem } from 'react-tools';

export enum AppRoute {
  ChannelView = 'CHANNEL_VIEW',
  EventView = 'EVENT_VIEW',
  PlayerView = 'PLAYER_VIEW',
  GroupView = 'GROUP_VIEW',
}

export interface AppRoutingContextData {
  currentRoute: string;
  pushRoute: (route: AppRoute) => void;
  title: string;
  barButtons: ButtonItem[];
  setTitle: (title: string) => void;
  setBarButtons: (buttons: ButtonItem[]) => void;
}

const contextDefaultValue: AppRoutingContextData = {
  currentRoute: AppRoute.ChannelView,
  pushRoute: (route: AppRoute) => {},
  title: 'On Demand',
  barButtons: [],
  setTitle: (title: string) => {},
  setBarButtons: (buttons: ButtonItem[]) => {},
};

export const AppRoutingContext =
  React.createContext<AppRoutingContextData>(contextDefaultValue);

export class AppRoutingContextProvider extends React.Component<
  { children: any },
  AppRoutingContextData
> {
  constructor(props: { children: any }) {
    super(props);
    this.state = {
      ...contextDefaultValue,
      pushRoute: this.pushRoute,
      setTitle: this.setTitle,
      setBarButtons: this.setBarButtons,
    };
  }

  pushRoute = (route: AppRoute) => {
    this.setState({ ...this.state, currentRoute: route, barButtons: [] });
  };

  setTitle = (title: string) => {
    if (this.state.title !== title) {
      this.setState({ ...this.state, title });
    }
  };

  setBarButtons = (buttons: ButtonItem[]) => {
    if (this.state.barButtons !== buttons) {
      this.setState({ ...this.state, barButtons: buttons });
    }
  };

  render() {
    return (
      <AppRoutingContext.Provider value={this.state}>
        {this.props.children}
      </AppRoutingContext.Provider>
    );
  }
}
