import { useAllPlayers, useAppContext, usePlayers } from "duck";
import { useCallback, useMemo } from "react";

import {
  Group,
  GroupAndSite,
  GroupFolder,
  GroupFolderTreeItem,
  GroupTreeItem,
} from "@models";
import { NavigatorItem } from "@navigator";

export const useGroupFolders = () => {
  const groups = useGroups();

  const groupIds = useMemo(() => groups.map((e) => e.id), [groups]);

  const { folders, foldersAndFolders, folderGroups } = useAppContext();

  const foldersAndFoldersChildIds = useMemo(
    () => foldersAndFolders.map((e) => e.childId),
    [foldersAndFolders]
  );

  const folderGroupChildIds = useMemo(
    () => folderGroups.map((e) => e.groupId),
    [folderGroups]
  );

  const availableFolders = useMemo(() => {
    const baseFolderIds = folders
      .filter((e) => !foldersAndFoldersChildIds.includes(e.folderId))
      .map((e) => e.folderId);

    let result = Array.from(new Set(baseFolderIds));

    const findParentIdRecurse = (id: number) => {
      const parent = foldersAndFolders.find((f) => f.childId === id);
      if (parent) {
        result.push(parent.parentId);
        findParentIdRecurse(parent.parentId);
      }
    };

    baseFolderIds.forEach((id) => findParentIdRecurse(id));

    result = Array.from(new Set(result));

    return result.map(
      (e) => folders.find((f) => f.folderId === e) as GroupFolder
    );
  }, [groupIds, folderGroups, foldersAndFolders, folders]);

  const mapFolderToNavigatorItem = useCallback(
    (
      folder: GroupFolder
    ): NavigatorItem<GroupTreeItem | GroupFolderTreeItem> => {
      const subFolders = foldersAndFolders
        .filter((e) => e.parentId === folder.folderId)
        .map(
          (e) => folders.find((f) => f.folderId === e.childId) as GroupFolder
        );

      const subGroups = folderGroups
        .filter((fg) => fg.folderId === folder.folderId)
        .map(
          (fg) =>
            groups.find(
              (g) => g.id === fg.groupId
            ) as NavigatorItem<GroupTreeItem>
        );

      return {
        id: folder.folderId,
        label: folder.name,
        value: folder,
        children: [
          ...subFolders
            .map((e) => mapFolderToNavigatorItem(e))
            .filter((e) => !!e && e.children && e.children.length > 0),
          ...subGroups.filter((e) => !!e),
        ] as NavigatorItem<GroupTreeItem | GroupFolderTreeItem>[],
      };
    },
    [foldersAndFolders, folderGroups, folders, groups]
  );

  const orphanGroups = useMemo(
    () => groups.filter((g) => !folderGroupChildIds.includes(g.id as number)),
    [groups, folderGroupChildIds]
  );

  const result = useMemo(
    () =>
      availableFolders
        .map((e) => mapFolderToNavigatorItem(e))
        .filter((e) => e.children && e.children.length > 0)
        .concat(orphanGroups),
    [availableFolders, mapFolderToNavigatorItem, orphanGroups]
  );

  return useMemo(() => (result.length ? result : groups), [result, groups]);
};

export const useGroups = () => {
  const players = usePlayers();
  const siteIds = useMemo(
    () => Array.from(new Set(players.map((e) => e.site.id))),
    [players]
  );

  const { groups, groupsAndSites } = useAppContext();

  const availableGroupsAndSites = useMemo(
    () => groupsAndSites.filter((gs) => siteIds.includes(gs.siteId)),
    [groupsAndSites, siteIds]
  );

  const availableGroups = useMemo(
    () =>
      groups.filter((g) =>
        availableGroupsAndSites.find((ags) => ags.groupId === g.groupId)
      ),
    [groups, availableGroupsAndSites]
  );

  const mapGroupToNavigatorItem = useCallback(
    (group: Group): NavigatorItem<GroupTreeItem> => {
      const groupAndSite = (
        availableGroupsAndSites.filter(
          (e) => e.groupId === group.groupId
        ) as GroupAndSite[]
      ).map((gs) => gs.siteId);

      const devices = players.filter((e) => groupAndSite.includes(e.site.id));

      const groupTreeItem: GroupTreeItem = {
        id: group.groupId,
        name: group.name,
        devices,
      };

      const groups: NavigatorItem<GroupTreeItem>[] = availableGroups
        .filter((group) => group.parentId === group.groupId)
        .map((e) => mapGroupToNavigatorItem(e));

      return {
        id: group.groupId,
        label: group.name,
        value: groupTreeItem,
        children: groups,
      };
    },
    [availableGroups, availableGroupsAndSites, players]
  );

  return useMemo(
    () => availableGroups.map((g) => mapGroupToNavigatorItem(g)),
    [availableGroups, mapGroupToNavigatorItem]
  );
};
