import React from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Theme, makeStyles, createStyles } from '@material-ui/core';
import classes from '*.module.css';

interface ConfirmDialogProps {
  open: boolean;
  confirm: () => void;
  cancel: () => void;
  text: string;
  title: string;
  confirmLabel: string;
  cancelLabel: string;
}


export const ConfirmDialog = ({ cancel, confirm, text, open, title, cancelLabel, confirmLabel }: ConfirmDialogProps) => (
  <Dialog
    open={open}
    onClose={cancel}
  >
    <DialogTitle >{title}</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description" color={'textSecondary'}>
        {text}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={cancel} variant={'contained'} color={'secondary'}>
        {cancelLabel}
      </Button>
      <Button onClick={confirm} variant={'contained'} color={'secondary'}>
        {confirmLabel}
      </Button>
    </DialogActions>
  </Dialog>
);