import React from 'react';

import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { useCallback } from 'react';
import { NavigatorItem } from './models';
import RightArrow from '@material-ui/icons/KeyboardArrowRight';

export interface NavigatorListItemProps<T> {
  item: NavigatorItem<T>;
  selectItem: (item: NavigatorItem<T>) => void;
  extraButtonClick?: (buttonId: string, item: NavigatorItem<T>) => void;
}

export const NavigatorListItem = <T extends unknown>({
  item,
  selectItem,
}: NavigatorListItemProps<T>) => {
  const onClick = useCallback(
    (e: React.MouseEvent) => selectItem(item),
    [selectItem]
  );

  return (
    <ListItem button data-testid='navigator-default-list-item'>
      <ListItemText primary={item.label} onClick={onClick} />
      {(item.hasChildren || (item.children && item.children.length)) && (
        <ListItemIcon data-testid='navigator-default-list-item-icon'>
          <RightArrow />
        </ListItemIcon>
      )}
    </ListItem>
  );
};
