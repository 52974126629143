import { useGroups } from 'group-view/hooks';
import React, { useCallback } from 'react';

import {
    createStyles, Drawer, List, ListItem, ListItemIcon, ListItemText, makeStyles
} from '@material-ui/core';
import AccountTree from '@material-ui/icons/AccountTree';
import InvertSelection from '@material-ui/icons/FlipToBack';
import SelectAll from '@material-ui/icons/SelectAll';
import DeselectAll from '@material-ui/icons/TabUnselected';

export enum PlayerMenuAction {
  SelectAll = 'SELECT_ALL',
  UnselectAll = 'UNSELECT_ALL',
  InvertSelection = 'INVERT_SELECTION',
  SelectByGroup = 'SELECT_BY_GROUP',
}

const useStyles = makeStyles(() =>
  createStyles({
    listItem: {
      cursor: 'pointer',
    },
  })
);

interface DrawerMenuProps {
  open: boolean;
  toggle: () => void;
  onClick: (action: PlayerMenuAction) => void;
}

export const DrawerMenu = ({ open, toggle, onClick }: DrawerMenuProps) => {
  const handleClick = useCallback(
    (menuItem: PlayerMenuAction) => () => onClick(menuItem),
    [onClick]
  );

  const classes = useStyles();
  const groups = useGroups();

  return (
    <Drawer anchor={'bottom'} open={open} onClose={toggle}>
      <List component="nav">
        <ListItem
          className={classes.listItem}
          onClick={handleClick(PlayerMenuAction.SelectAll)}
        >
          <ListItemIcon>
            <SelectAll />
          </ListItemIcon>
          <ListItemText id="switch-list-label-wifi" primary="Select All" />
        </ListItem>

        <ListItem onClick={handleClick(PlayerMenuAction.UnselectAll)}>
          <ListItemIcon>
            <DeselectAll />
          </ListItemIcon>
          <ListItemText id="switch-list-label-wifi" primary="Deselect All" />
        </ListItem>

        <ListItem
          className={classes.listItem}
          onClick={handleClick(PlayerMenuAction.InvertSelection)}
        >
          <ListItemIcon>
            <InvertSelection />
          </ListItemIcon>
          <ListItemText
            id="switch-list-label-wifi"
            primary="Invert Selection"
          />
        </ListItem>

        {groups.length > 0 && (
          <ListItem
            className={classes.listItem}
            onClick={handleClick(PlayerMenuAction.SelectByGroup)}
          >
            <ListItemIcon>
              <AccountTree />
            </ListItemIcon>
            <ListItemText
              id="switch-list-label-wifi"
              primary="Select by Group"
            />
          </ListItem>
        )}
      </List>
    </Drawer>
  );
};
